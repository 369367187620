import type { CalendarOptions, EventContentArg } from '@fullcalendar/core';
import type { ResourceLabelContentArg } from '@fullcalendar/resource';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import type { createElement } from '@fullcalendar/core/preact';

import { common_calendarOptions, main } from './common';

import './index.html';

const calendarOptions: CalendarOptions = {
  ...common_calendarOptions,
  plugins: [
    ...(common_calendarOptions.plugins ?? []),
    timeGridPlugin,
    resourceTimelinePlugin,
  ],
  headerToolbar: {
    start: 'resourceTimeline,timeGridWeek',
    center: 'title',
    end: 'prev,next today',
  },
  buttonText: {
    resourceTimeline: 'day',
  },
  initialView: 'resourceTimeline',
  resourceLabelContent: (
    arg: ResourceLabelContentArg,
    h: typeof createElement
  ) => {
    const calendar = arg.resource.extendedProps.calendar;
    if (calendar) {
      const embed_link = `https://calendar.google.com/calendar/embed?ctz=America%2FNew_York&src=${calendar}`;
      const ical_link = `https://calendar.google.com/calendar/ical/${calendar}/public/basic.ics`;
      return h(
        'span',
        null,
        h('a', { href: embed_link }, arg.resource.title),
        ' ',
        h('a', { href: ical_link }, ' [iCal 📅]')
      );
    }
    return true;
  },
  eventContent: (arg: EventContentArg, h: typeof createElement) => {
    if (arg.view.type != 'resourceTimeline') {
      let resources = arg.event
        .getResources()
        .map((resource) => resource.id.trim())
        .join('; ');
      return h(
        'div',
        { class: 'fc-event-main' },
        h(
          'div',
          { class: 'fc-event-main-frame' },
          h('div', { class: 'fc-event-time' }, arg.timeText),
          h(
            'div',
            { class: 'fc-event-title-container' },
            h(
              'div',
              { class: 'fc-event-title fc-sticky' },
              `${resources}: ${arg.event.title}`
            )
          )
        )
      );
    }
    return true;
  },
};

main(calendarOptions, true);
