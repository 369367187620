import { Calendar, type CalendarOptions } from '@fullcalendar/core';
import iCalendarPlugin from '@fullcalendar/icalendar';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import { unique_colors } from 'unique-colors';
import _ALL_SHOPS from './shops.json';
export const ALL_SHOPS: { [key: string]: Shop } = _ALL_SHOPS;

interface Shop {
  calendar?: string;
  children?: string[];
}

export const common_calendarOptions: CalendarOptions = {
  schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
  plugins: [iCalendarPlugin, resourceTimeGridPlugin],
  allDaySlot: false,
  nowIndicator: true,
  headerToolbar: { start: '', center: 'title', end: '' },
  initialView: 'resourceTimeGrid',
  height: 'auto',
  slotMinTime: '8:00',
  slotMaxTime: '22:00',
  displayEventTime: true,
  businessHours: {
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
    startTime: '10:00',
    endTime: '21:00',
  },
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  },
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    hour12: false,
  },
};

export function main(
  calendarOptions: CalendarOptions,
  showNewTools: boolean = false,
  shops: { [key: string]: Shop } = ALL_SHOPS
) {
  const calendarEl = document.getElementById('calendar');
  calendarOptions.resources = Object.entries(shops).map(([shop_name, shop]) => {
    return {
      id: shop_name,
      title: shop_name,
      extendedProps: {
        calendar: shop.calendar,
      },
      children: shop.children?.map((tool) => {
        return {
          id: tool,
          title: tool,
        };
      }),
    };
  });
  const calendar = new Calendar(calendarEl!, calendarOptions);

  const colors: string[] = unique_colors(Object.keys(shops).length);
  Object.entries(shops)
    .filter(([shop_name, shop]) => shop.calendar !== undefined)
    .forEach(([shop_name, shop], idx) => {
      calendar.addEventSource({
        id: shop_name,
        url: '/calendar/ical/' + shop.calendar + '/public/basic.ics',
        format: 'ics',
        color: colors[idx],
        eventDataTransform: (eventData) => {
          // clear the url to prevent clicking on the event
          delete eventData.url;

          const match = eventData?.title?.match(/([^\/]*) \| ([^-]*) - (.*)/);
          if (match) {
            const [, member, _event_shop, tool] = match;
            eventData.title = `${member}`;
            eventData.resourceId = tool;
          } else {
            // assume any event not for a specific tool is for the shop as a whole
            eventData.resourceId = shop_name;
          }
          if (showNewTools) {
            if (!calendar.getResourceById(eventData.resourceId)) {
              calendar.addResource(
                { id: eventData.resourceId, title: eventData.resourceId },
                false
              );
            }
          }

          // Add background event if resource has children
          let tool_resource = calendar.getResourceById(eventData.resourceId);
          let children = tool_resource?.getChildren();
          if (tool_resource && children?.length) {
            let new_event = {
              ...eventData,
              resourceIds: [
                tool_resource.id,
                ...children.map((resource) => resource.id),
              ],
              display: 'background',
            };
            calendar.addEvent(new_event, shop_name);
          }

          return eventData;
        },
      });
    });

  calendar.render();
  //calendar.gotoDate('2022-06-27');

  return calendar;
}
